





































































/* eslint-disable @typescript-eslint/camelcase */

import { ValidationProvider } from 'vee-validate/dist/vee-validate.full.esm'
import { Component, Vue, Watch } from 'vue-property-decorator'
import InputNumber from '@/components/Controls/InputNumber.vue'
import InputText from '@/components/Controls/InputText.vue'
import InputTextarea from '@/components/Controls/InputTextarea.vue'
import Calendar from '@/components/Controls/Calendar.vue'
import { componentsImports } from '../FormFields/index'

type Outbuilding = {
  buildingType: string | null;
  buildingUse: string | null;
  generalCondition: string | null;
  buildingArea: number | null;
}

type ServerData = { [key: string]: string | number | null | string[] | object[] }

@Component({
  components: {
    ...componentsImports(),
    ValidationProvider,
    InputNumber,
    InputText,
    InputTextarea,
    Calendar
  }
})
export default class RuralCroppingFarm extends Vue {
  formData () {
    const data: ServerData = {
      UseDescription: this.use,
      GeneralCondition: this.generalCondition,
      NumberOfBedrooms: this.bedrooms,
      NumberOfBathrooms: this.bathrooms,
      FloorArea: this.floorArea,
      FloorConstruction: this.floorConstruction,
      WallConstruction: this.wallConstruction,
      RoofConstruction: this.roofConstruction,
      NumberOfDams: this.dams,
      TotalDamCapacity: this.totalDamCapacity,
      HectaresIrrigated: this.hectaresIrrigated,
      NumberOfOperationalBores: this.bores
    }

    data.Outbuildings = this.outbuildings.map(o => ({
      BuildingType: o.buildingType,
      UseDescription: o.buildingUse,
      Condition: o.generalCondition,
      Area: o.buildingArea
    }))

    return data
  }

  loadData (data: any) {
    this.use = data.UseDescription
    this.generalCondition = data.GeneralCondition
    this.bedrooms = data.NumberOfBedrooms
    this.bathrooms = data.NumberOfBathrooms
    this.floorArea = data.FloorArea
    this.floorConstruction = data.FloorConstruction
    this.wallConstruction = data.WallConstruction
    this.roofConstruction = data.RoofConstruction
    this.dams = data.NumberOfDams
    this.totalDamCapacity = data.TotalDamCapacity
    this.hectaresIrrigated = data.HectaresIrrigated
    this.bores = data.NumberOfOperationalBores

    if (!data.Outbuildings) return

    data.Outbuildings.forEach((t: any) => {
      this.outbuildings.push({
        buildingType: t.BuildingType,
        buildingUse: t.UseDescription,
        generalCondition: t.Condition,
        buildingArea: t.Area
      })
    })

    this.numberOfOutbuildings = data.Outbuildings.length
  }

  propertyType () {
    return 'RuralCroppingFarm'
  }

  @Watch('numberOfOutbuildings')
  outbuildingsChanged (newVal: number, oldVal: number | null) {
    if (newVal < 0) return
    if (oldVal === null) oldVal = 0
    if (newVal === this.outbuildings.length) return

    if (newVal > oldVal) {
      for (let i = oldVal; i < newVal; i++) {
        this.outbuildings.push(this.newOutbuilding())
      }
    } else {
      this.outbuildings = this.outbuildings.slice(0, newVal)
    }
  }

  outbuildings: Outbuilding[] = []

  newOutbuilding () {
    return {
      buildingType: null,
      buildingUse: null,
      generalCondition: null,
      buildingArea: null
    }
  }

  use = null
  generalCondition = null
  bedrooms = null
  bathrooms = null
  floorArea = null
  floorConstruction = null
  wallConstruction = null
  roofConstruction = null
  numberOfOutbuildings = null
  dams = null
  totalDamCapacity = null
  hectaresIrrigated = null
  bores = null
}
